import { ISideDrawerComponentProps } from "../../../lib/types/components";
import { publishWithUsType, sendPublishWithUsEvent } from "../../../lib/utils/constants/AnalyticsConstants";
import { GDC_WEB_URL } from "../../../lib/utils/constants/GlobalConstants";
import { dynamicCssSize } from "../../../lib/utils/constants/PagesConstants";
import CustomLinkComponent from "../CusotmLink/CustomLinkComponent";
import CustomImageComponent from '../CustomImage/CustomImageComponent';

const SideDrawerComponent = (props: ISideDrawerComponentProps) => {
    const {handleHamburgerClick, menuOpen, navigationDataLinks} = props;

    const navData = navigationDataLinks;

    return (
        navData ?
            <>
                <style jsx>{`
                  .drawer {
                    position: fixed;
                    z-index: 999;
                    height: 100%;
                    right: 0;
                    overflow: auto;
                    transition: .3s;
                  }

                  .subLinksContainer {
                    display: flex;
                    margin-left: 30px;
                    margin-bottom: 10px;
                    flex-wrap: wrap;
                  }

                  .clickOutside {
                    position: relative;
                    font-family: var(--font-nunito);
                    font-size: 18px !important;
                    font-weight: 500 !important;
                    color: #d8d8d8;
                    flex: 1 1 50%;
                    text-decoration: none;
                  }

                  .clickInside {
                    position: relative;
                    font-family: var(--font-nunito);
                    font-size: 18px !important;
                    font-weight: 500 !important;
                    color: #d8d8d8;
                  }

                  .clickOutside:hover::after {
                    content: "";
                    position: absolute;
                    display: block;
                    width: 100%;
                    height: 100%;
                    top: 0;
                    right: 0;
                    margin-right: 20px;
                    transform-origin: bottom left;
                    transform: skew(-30deg, 0deg);
                    z-index: -1;
                    background: linear-gradient(to bottom, #b1fd2b, #31eb6f);
                  }

                  .clickInside:hover::after {
                    content: "";
                    position: absolute;
                    display: block;
                    width: 100%;
                    height: 100%;
                    top: 0;
                    left: -9px;
                    margin-right: 20px;
                    transform-origin: center;
                    transform: skew(-10rad, 0deg);
                    z-index: -1;
                    background: linear-gradient(to bottom, #b1fd2b, #31eb6f);
                  }

                  .itemTitle {
                    padding: 2px 8px;
                    margin: 0;
                  }

                  .heading {
                    padding: 10px;
                  }

                  .menuContainer {
                    flex-direction: column;
                    align-items: flex-start;
                    padding: 10px;
                  }

                  .drawer > div {
                    background: #000;
                    height: 100%;
                    margin-left: 70%;
                    opacity: 0.99;
                    overflow: auto;
                  }

                  .navbar {
                    position: relative;
                    display: flex;
                    flex-wrap: wrap;
                    align-items: center;
                    padding: .5rem 1rem;
                    margin-top: 50px;
                  }

                  .navItem {
                    font-family: var(--font-nunito);
                    font-size: 19px !important;
                    font-weight: 800 !important;
                    color: #fff;
                    opacity: 0.5;
                    text-transform: uppercase !important;
                    transition: .3s !important;
                    text-decoration: none;
                    width: 100%;
                    margin: 0;
                  }

                  .navItemClickable {
                    font-family: var(--font-nunito);
                    font-size: 19px !important;
                    font-weight: 800 !important;
                    color: #d8d8d8;
                    text-transform: uppercase !important;
                    text-decoration: none;
                    width: 100%;
                    margin: 0;
                    padding: 4px 0;
                    opacity: 0.5;
                  }

                  .navItemClickable:hover::after {
                    content: "";
                    position: absolute;
                    display: block;
                    width: 100%;
                    height: 100%;
                    top: 0;
                    left: -18px;
                    transform-origin: bottom left;
                    transform: skew(-10rad, 0deg);
                    z-index: -1;
                    background: linear-gradient(to bottom, #b1fd2b, #31eb6f);
                  }

                  p:hover {
                    color: black;
                    opacity: 1;
                  }

                  .imgContainer1 {
                    height: 25px;
                    width: 20px;
                    position: relative;
                  }

                  .gameRow {
                    padding: 5px;
                    align-items: center;
                  }

                  .imgCol {
                    padding-bottom: 2px;
                    max-width: 20px;
                  }

                  .headingLinkContainer{
                    max-width: 250px;
                    width: 100%;
                    position: relative;
                  }

                  @media screen and (max-width: 1200px) {
                    .drawer > div {
                      margin-left: 50%;
                    }
                  }

                  @media screen and (max-width: 767px) {
                    .drawer > div {
                      margin-left: 20%;
                    }

                    .clickInside {
                      font-size: 15px;
                    }

                    .clickOutside {
                      font-size: 15px;
                    }

                    .itemTitle {
                      padding: 2px 8px;
                      font-size: ${dynamicCssSize(55,12)};
                    }

                    .navbar {
                      padding: 0;
                    }

                    .navItem {
                      font-size: 16px !important;
                    }

                    .navItemClickable {
                      font-size: 16px !important;
                    }

                    .subLinksContainer {
                      margin-bottom: 5px;
                    }

                  }

                `}
                </style>
                <aside
                    className={"drawer"}
                    style={menuOpen ? {width: "100%"} : {width: 0}}
                    onClick={() => handleHamburgerClick()}
                >
                    <div>
                        <nav className={"navbar"}
                             style={menuOpen ? {transition: 'opacity 0.5s ease-in', opacity: 0.99} : {opacity: 0}}>
                            <div className={"menuContainer container-fluid"}>
                                {navData.map((data, index) => {
                                    return (
                                        <div key={"gameRow" + index} style={{width: "100%"}}>
                                            <div className={"row gameRow"}>
                                                <div className={"col imgCol"}>
                                                    <div className={"imgContainer1"}>
                                                        <CustomImageComponent
                                                            layout={"fill"}
                                                            objectFit={"contain"}
                                                            src={data.img}
                                                            alt={"game icon image"}
                                                        />
                                                    </div>
                                                </div>

                                                {data.url ?
                                                    <div className={"col"} style={{padding: '2px 15px'}}>
                                                        <div className={"headingLinkContainer"}>
                                                            <CustomLinkComponent
                                                                href={data.url}
                                                                mobStyle={{
                                                                    textDecoration: "none"
                                                                }}
                                                                desktopStyle={{
                                                                    textDecoration: "none"
                                                                }}
                                                            >
                                                            <span onClick={() => {
                                                                data.url === GDC_WEB_URL && sendPublishWithUsEvent(publishWithUsType.MOBILE)
                                                            }}>
                                                                <p className={"navItemClickable"}>
                                                                    {data.heading}
                                                                </p>
                                                            </span>
                                                            </CustomLinkComponent>
                                                        </div>
                                                    </div>
                                                    :
                                                    <div className={"col"} style={{paddingLeft: '14px'}}>
                                                        <div className={"navItem"} key={"navItem" + index}>
                                                            {data.heading}
                                                        </div>
                                                    </div>
                                                }

                                            </div>
                                            {data.navLinks.length > 0 &&
                                                <div className='subLinksContainer'>
                                                    {data.navLinks.map((item, i) => {
                                                        return (
                                                            item.outside ?
                                                                <a href={item.link} className='clickOutside' key={"clickOutside_" + i}>
                                                                    <p className='itemTitle'>
                                                                        {item.title}
                                                                    </p>
                                                                </a>
                                                                :
                                                                <CustomLinkComponent href={item.link} key={i}
                                                                                     mobStyle={{
                                                                                         flex: "1 1 50%",
                                                                                         textDecoration: "none"
                                                                                     }}
                                                                                     desktopStyle={{
                                                                                         flex: "1 1 50%",
                                                                                         textDecoration: "none"
                                                                                     }}
                                                                >
                                                                    <span className='clickInside'>
                                                                        <p className='itemTitle'>
                                                                            {item.title}
                                                                        </p>
                                                                    </span>
                                                                </CustomLinkComponent>
                                                        )
                                                    })
                                                    }
                                                </div>}
                                        </div>

                                    )
                                })

                                }


                            </div>
                        </nav>
                    </div>
                </aside>
            </>
            : <></>
    )
}

export default SideDrawerComponent;